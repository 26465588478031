import React from "react";
import { Link } from "react-router-dom";
import Carousal from "../Components/carousal/Carousal";
import Slider2 from "../Components/carousal/ProductSlider/Slider1";
import Slider1 from "../Components/carousal/Slider/Slider";
import Layout from "./../Components/Layout";
import { cos, disposable, paper, products } from "./../Data/allData";

const Homepage = () => {
  return (
    <Layout>
      <div>
        <Carousal />
      </div>

      {/* mission para start here */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <h1 style={{ fontFamily: "'Times New Roman', Times, serif" }}>
              Our Mission
            </h1>
            <hr className="mx-auto mt-2 " />
          </div>
          <div className="row">
            <div className="col-md-12 text-center mt-3">
              <p className="missionpara" style={{ color: "#000" }}>
                Our mission is to make your life easier. In our paper disposable
                and cosmetics business, our mission is to redefine
                sustainability in the beauty industry. We are committed to
                offering innovative and eco-friendly paper-based disposable
                products that cater to the needs of modern consumers while
                minimizing environmental impact. Our vision extends beyond
                traditional beauty practices, as we aim to revolutionize the way
                disposable cosmetics are perceived and utilized.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mission" />
      {/* mission end here */}

      <Slider2
        title="Machinery of Paper Industry "
        para="Bao Zong Hao Limited offers a diverse range of paper categories, including packaging, printing, and specialty papers, tailored to meet various business needs."
        data={paper}
      />
      <br />
      <br />

      {/* paper video  */}
      {/* <video id="background-video" autoPlay loop muted >
  <source src="./images/paperv.mp4" type="video/mp4" />
</video> */}
      <div className="video my-5 ">
        <video autoPlay loop muted playsInline className="back-video ">
          <source src="./assets/paper1.mp4" type="video/mp4" />
        </video>
        <div className="content text-center">
          <p style={{ color: "#fff" }}>Paper Industry</p>
          <button className="btn ">Explore</button>
        </div>
      </div>

      {/* video end here */}

      {/* cosmetic slider */}
      <div className="container my-5">
        <div className="row">
          <div className="col-md-12">
            <Slider1 data={products} />
          </div>
        </div>
      </div>

      {/* /////////////////////// */}

      <div className="container margin">
        <div className="row bg-light">
          <div className="col-md-4">
            <div className="row mb-2">
              <img src="./assets/cos1.png" alt="" />
            </div>
            <div className="row">
              <div>
                <video autoPlay loop muted playsInline>
                  <source src="./assets/cos.mp4" type="video/mp4" />
                </video>
              </div>
              {/* <img
                src="./assets/t2.jpg"
                style={{ height: "250px" }}
                alt="image"
              /> */}
            </div>
          </div>
          <div className="col-md-4 text-center bg-light ">
            <h3
              className="mt-3"
              style={{ fontFamily: "'Times New Roman', Times, serif" }}
            >
              Cosmetics
            </h3>
            <hr className="mx-auto mt-2 " />
            <p style={{ textAlign: "justify", fontFamily: "cursive" }}>
              Cosmetics are products designed to enhance beauty and improve
              personal care.They range from skincare essentials like
              moisturizers and serums to makeup items such as
              lipsticks,foundations and eyeshadows. Crafted with care, cosmetics
              not only highlight natural features but also help in nourishing
              and protecting the skin.
            </p>
            <Link to="/product">
              <button className="btn mb-3">See all</button>
            </Link>
          </div>
          <div className="col-md-4">
            <div className="row mb-2">
              <div>
                <video autoPlay loop muted playsInline>
                  <source src="./assets/paper.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="row">
              <img src="./assets/cos2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* end here */}

      <br />
      <br />
      <Slider2 title="Best Sellers in Cosmetics" data={cos} />
      <br />
      <Slider2
        title="Best Sellers in Disposable"
        para="Bao Zong Hao Limited offers a variety of disposable products, including eco-friendly and durable options for everyday convenience. Their range is ideal for catering, events, and personal use, ensuring practicality and sustainability."
        data={disposable}
      />
    </Layout>
  );
};

export default Homepage;

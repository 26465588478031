import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import './slider.css';



const Slider1 = ({data}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container container">
      <Slider {...settings}>
      
      {data.map(title => (
        <div key={title.id} className="flex items-center justify-center  bg-white">
          <div  className="relative flex items-center justify-center w-64 h-64  rounded-full border-8 " style={{borderColor:"#9a050C"}}>
          <div className="absolute w-48 h-48  rounded-full flex items-center justify-center" style={{backgroundColor:"#9a050C"}}>
            <div className="w-32 h-32  rounded-md flex items-center justify-center shadow-md">
              <img
                src={title.image} // Replace with actual image path
                alt={title.id}
                className="w-3/4 h-auto"
              />
            </div>
           
          </div>
        </div>
        </div>
          // <div key={title.id} className="skincare-container mt-5">
          //   <div className="circle-background">
          //     <div className="circle-outline">
          //       <img src={title.image} alt=""
          //         className="product-image"
          //       />
          //     </div>
          //   </div>
            
          // </div>
        ))}
 
      
      </Slider>
    </div>
  );
};

export default Slider1;

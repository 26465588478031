import React, { useRef} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './carousal.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


 const Carousal =  ()  => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <>
   

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
         
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper">
        <SwiperSlide><img src='./assets/4.png'  alt='slider1'/></SwiperSlide>
        <SwiperSlide><img src='./assets/carousal1.png'  alt='slider2'/></SwiperSlide>
        {/* <SwiperSlide><img src='./assets/carousal2.png'  alt='slider3'/></SwiperSlide> */}
        <SwiperSlide><img  src='./assets/carousal3.png'  alt='slider4'/></SwiperSlide>
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
   
    </>
  );
}
export default Carousal;

const menu = [
  {
    id: 1,
    title: "Mask",
    category: "Disposable",
    price: 1200,
    img: "./assets/Disposable/8.png",
    desc: `A mask is a protective covering worn over the face, used for various purposes including health, safety, and cosmetic applications. It can help prevent the spread of illness, filter air, or serve as a fashion accessory.`,
  },
  {
    id: 2,
    title: "Face wash",
    category: "Cosmetics",
    price: 1700,
    img: "./assets/cosmetics/face wash.png",
    desc: `A gentle facewash helps cleanse the skin by removing dirt, oil, and impurities without stripping moisture. Regular use of facewash can promote clear, refreshed skin and prevent breakouts. `,
  },
  {
    id: 3,
    title: "Carbon Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/1.png",
    desc: `Carbon paper is a thin, coated sheet used to create duplicate copies of handwritten or typed documents. By placing it between two sheets of paper, it transfers the original text onto the second sheet through pressure.`,
  },
  {
    id: 4,
    title: "Glass",
    category: "Disposable",
    price: 1200,
    img: "./assets/Disposable/4.png",
    desc: `Glass is a transparent, rigid material made from silica and other compounds, commonly used for windows, containers, and decorative items. Its clarity and versatility make it ideal for various applications, from functional uses to artistic creations. `,
  },
  {
    id: 5,
    title: "Hair color",
    category: "Cosmetics",
    price: 1200,
    img: "./assets/cosmetics/hair color.png",
    desc: `Hair color can transform your look, adding vibrancy and enhancing your natural beauty with a range of shades from subtle highlights to bold hues. `,
  },

  {
    id: 6,
    title: "Carbonless",
    category: "Paper",
    price: 2200,
    img: "./assets/Paper/14.png",
    desc: `Carbonless paper, also known as NCR (No Carbon Required) paper, allows for multiple copies of a document without the need for carbon paper. It uses a special coating to transfer the impression from the top sheet to the sheets below, producing duplicate or triplicate copies simultaneously.`,
  },
  {
    id: 7,
    title: "Ice",
    category: "Disposable",
    price: 1200,
    img: "./assets/Disposable/Ice.png",
    desc: `Ice is frozen water, typically used to cool drinks and preserve food. It forms as a solid when water reaches temperatures below 32°F (0°C) and can be found in various shapes, such as cubes, crushed, or blocks, depending on its intended use. `,
  },
  {
    id: 8,
    title: "Ponds (Facial Form)",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Cosmetics/7.png",
    desc: `POND'S offers a variety of skincare solutions, including the POND'S Pure Bright range for radiant, even-toned skin, and the POND'S Acne Solution line to target and prevent breakouts`,
  },
  {
    id: 9,
    title: "Hair Removal Spray",
    category: "Cosmetics",
    price: 2500,
    img: "./assets/cosmetics/Hair removal spray.png",
    desc: `Hair removal sprays provide a quick and painless solution to eliminate unwanted hair, leaving skin smooth and hair-free in minutes. `,
  },

  {
    id: 10,
    title: "Graph Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/12.png",
    desc: `Graph paper features a grid of evenly spaced lines, creating squares that help with drawing precise graphs, charts, and diagrams. It’s commonly used for mathematical plotting, engineering designs, and creating structured layouts.`,
  },

  {
    id: 11,
    title: "Plates",
    category: "Disposable",
    price: 1700,
    img: "./assets/Disposable/1.png",
    desc: `Plates are flat, typically round dishes used for serving and eating food. They come in various materials like ceramic, glass, plastic, and metal, and are designed for durability and ease of use during meals. `,
  },

  {
    id: 12,
    title: "Relvon Clsk",
    category: "Cosmetics",
    price: 1200,
    img: "./assets/cosmetics/Cosmetics/1.png",
    desc: `REVLON CLSK offers a range of rich, vibrant hair color shades to suit various preferences. The available shades include CLSK-41 Medium Brown for a natural look, CLSK-10 Black for a bold and deep color, CLSK-51 Light Brown for a soft, subtle hue, CLSK-20 Brown Black for a balanced mix of tones, and CLSK-30 Dark Brown for a deeper, warm shade. `,
  },

  {
    id: 13,
    title: "Tea",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/Tea.png",
    desc: `Tea disposables, such as tea bags and infusers, offer a convenient way to brew and enjoy tea without the need for traditional teapots or strainers. They provide an easy, mess-free solution for steeping single servings of tea.`,
  },
  {
    id: 14,
    title: "Chalk Paper",
    category: "Paper",
    price: 2200,
    img: "./assets/Paper/13.png",
    desc: `Chalk paper is a type of paper with a textured surface designed to mimic the feel of a chalkboard. It is often used for drawing or writing with chalk or chalk-like markers, providing a reusable surface for creative projects or teaching purposes.`,
  },
  {
    id: 15,
    title: "Nevia Men",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Cosmetics/15.png",
    desc: `NIVEA MEN offers a wide range of deodorants designed for long-lasting protection and freshness. Options include Black & White Invisible,, Deep Black Charcoal, Cool Kick Extra Day, Dry Comfort and Dry Impact and Fresh Energy for a revitalizing burst.  `,
  },
  {
    id: 16,
    title: "Construction Paper",
    category: "Paper",
    price: 2500,
    img: "./assets/Paper/11.png",
    desc: `Construction paper is a heavy, colored paper used for arts and crafts projects. It comes in a variety of vibrant colors and is known for its durability, making it ideal for creating collages, cutouts, and other creative projects. `,
  },
  {
    id: 17,
    title: "Eye Shadow",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Eye shadow.png",
    desc: `Eyeshadow adds depth and dimension to the eyes, enhancing their shape and color. Available in various shades and finishes, it allows for endless creativity, from subtle, everyday looks to bold, dramatic styles. `,
  },
  {
    id: 18,
    title: "Clay face wash",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Clay face wash.png",
    desc: `Clay face wash helps detoxify the skin by drawing out impurities, excess oil, and dirt, making it ideal for oily and combination skin types. `,
  },
  {
    id: 19,
    title: "Kraft Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/7.png",
    desc: `Kraft paper is a strong, brown paper made from unbleached wood pulp. It is known for its durability and natural, rustic appearance, and is commonly used for packaging, wrapping, and crafting.`,
  },
  {
    id: 20,
    title: "Cream face wash",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Cream face wash.png",
    desc: `Cream face wash gently cleanses while providing deep hydration, making it perfect for dry and sensitive skin types. Its creamy texture helps maintain the skin's natural moisture barrier, leaving it soft, smooth, and nourished after every wash. `,
  },
  {
    id: 21,
    title: "Foam face wash",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Foam face wash.png",
    desc: `Foam face wash offers a light and airy texture that effectively removes dirt, oil, and makeup, leaving the skin feeling refreshed and deeply cleansed. `,
  },
  {
    id: 22,
    title: "Micellar cleanser",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Micellar cleanser.png",
    desc: `Micellar cleansers use tiny micelle molecules to gently lift away dirt, makeup, and impurities without the need for rinsing, making them perfect for all skin types. `,
  },
  {
    id: 23,
    title: "Printing Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/4.png",
    desc: `Printing paper is designed specifically for use in printers and copiers, offering a smooth surface for clear, sharp text and images. It typically comes in various weights and finishes, such as matte or glossy, to suit different printing needs and applications.`,
  },

  {
    id: 24,
    title: "Gel face wash",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Gel face wash.png",
    desc: `Gel face wash provides a refreshing and lightweight cleanse, effectively removing impurities and excess oil without over-drying the skin. Formulated with hydrating ingredients, gel face wash is ideal for oily and combination skin types, leaving the complexion clean, smooth, and revitalized.`,
  },
  {
    id: 25,
    title: "Beauty Kit",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/4.png",
    desc: `A beauty kit typically includes a curated selection of skincare and makeup essentials designed to enhance and simplify your beauty routine. With products ranging from moisturizers and serums to lipsticks and eyeshadows, beauty kits cater to various needs, making them perfect for travel or everyday use.`,
  },

  {
    id: 26,
    title: "Rice Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/8.png",
    desc: `Rice paper is a thin, translucent paper made from the pith of the rice plant or other plant fibers. It is commonly used in Asian cuisine for wrapping spring rolls and in art for delicate painting and calligraphy due to its unique texture and absorbency.`,
  },
  {
    id: 27,
    title: "Different shades",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/6.png",
    desc: `It includes a curated selection of skincare and makeup essentials designed to enhance and simplify your beauty routine. With products ranging from moisturizers and serums to lipsticks and eyeshadows, beauty kits cater to various needs, making them perfect for travel or everyday use. `,
  },

  {
    id: 28,
    title: "Compact Kit",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/1.png",
    desc: `A compact kit is a portable beauty essential that typically includes a combination of powder foundation, blush, and sometimes eyeshadow, perfect for touch-ups on the go. `,
  },
  {
    id: 29,
    title: "Sticker",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/12.png",
    desc: `A sticker is a decorative or functional adhesive label that can be applied to various surfaces. It often features a design, message, or brand logo and can be used for decoration, labeling, or promotional purposes.`,
  },

  {
    id: 30,
    title: "Nivea (Dry Comfort)",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Cosmetics/18.png",
    desc: `NIVEA Dry Comfort is a reliable deodorant that provides long-lasting protection against sweat and body odor while keeping the skin feeling dry and fresh. Formulated with a special combination of ingredients, it ensures effective moisture control and comfort throughout the day, making it suitable for all skin types. `,
  },
  {
    id: 31,
    title: "FA Brighting & care",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Cosmetics/25.png",
    desc: `FA Brightening & Care offers a range of body care products designed to enhance skin radiance while providing effective odor protection. This line features delightful fragrances, including Caribbean Lemon, Aqua, Mystic Moment, Invisible Power Soft Freshness and Men Invisible Power, Pink Passion Sport variant offers an energizing scent perfect for active lifestyles.`,
  },

  {
    id: 32,
    title: "Tag Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/9.png",
    desc: `Tag paper is a thick, sturdy paper often used for creating tags, labels, or tickets. It has a rougher texture compared to standard paper, making it durable and suitable for applications that require strength and rigidity.`,
  },
  {
    id: 33,
    title: "Enchanteur Talcum Powder",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Cosmetics/12.png",
    desc: `Enchanteur Talcum Powder offers a luxurious range of fragrances designed to keep you feeling fresh and confident throughout the day. Each variant captures a unique essence: Enticing, Alluring, Charming, Romantic, Stunning and Desire. All varieties are formulated to absorb moisture and keep your skin soft and smooth. `,
  },

  {
    id: 34,
    title: "Mascara",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/Mascara.png",
    desc: `Mascara enhances the eyelashes by adding length, volume, and definition. It comes in various formulas and brushes, allowing you to achieve everything from a natural, everyday look to dramatic, full lashes.`,
  },
  {
    id: 35,
    title: "Tissue Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/10.png",
    desc: `Tissue paper is a thin, lightweight paper often used for wrapping delicate items, gift packaging, and crafts. It comes in various colors and can be used to add a decorative touch or provide cushioning.`,
  },

  {
    id: 36,
    title: "Moisturizer",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/Moisturizer.png",
    desc: `Moisturizer hydrates and nourishes the skin, helping to maintain its softness and elasticity. It creates a protective barrier to lock in moisture, preventing dryness and keeping your skin smooth and healthy.`,
  },
  {
    id: 37,
    title: "Cover Stock",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/5.png",
    desc: `Cover stock is a heavier, thicker paper used for making covers for books, business cards, and other printed materials that require added durability. It offers a sturdy and professional finish, often with a smooth or textured surface.`,
  },
  {
    id: 38,
    title: "Primer",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Primer.png",
    desc: `Primer creates a smooth base for makeup application by filling in pores and fine lines. It helps to enhance the longevity of your makeup, ensuring a more even and flawless finish while also controlling shine and improving overall skin texture.`,
  },

  {
    id: 39,
    title: "Hair Removal Cream",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/11.png",
    desc: `Hair removal cream provides a quick and painless method for eliminating unwanted hair, leaving the skin smooth and hair-free within minutes. `,
  },
  {
    id: 40,
    title: "Tracing Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/3.png",
    desc: `Tracing paper is a translucent paper used to trace or copy images and designs. Its see-through quality allows for easy transfer of details from one surface to another, making it a valuable tool for artists, designers, and architects.`,
  },

  {
    id: 41,
    title: "Setting Spray",
    category: "Cosmetics",
    price: 2200,
    img: "./assets/cosmetics/Setting Spray.png",
    desc: `Setting spray locks in makeup and helps it last longer by creating a protective layer over your face. It also helps to reduce shine and keep your makeup looking fresh throughout the day. `,
  },

  {
    id: 42,
    title: "Cotton Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/2.png",
    desc: `Cotton paper is made from cotton fibers, providing a high-quality, textured surface that is durable and absorbent. It is often used for stationery, high-end printing, and art projects, offering a premium feel and professional finish.`,
  },

  {
    id: 43,
    title: "Waxed Paper",
    category: "Paper",
    price: 1000,
    img: "./assets/Paper/6.png",
    desc: `Waxed paper is coated with a thin layer of wax to make it moisture-resistant and non-stick. It is commonly used in food preparation and storage, as well as for wrapping and lining surfaces to prevent sticking and protect from spills.`,
  },

  {
    id: 44,
    title: "Fork",
    category: "Disposable",
    price: 1200,
    img: "./assets/Disposable/3.png",
    desc: `Disposable fork are single-use utensils made from materials like plastic or biodegradable substances, designed for convenience and hygiene. They are ideal for events, takeout meals, and situations where reusable options aren't practical.`,
  },

  {
    id: 45,
    title: "Bottles",
    category: "Disposable",
    price: 1200,
    img: "./assets/Disposable/18.png",
    desc: `Bottles are containers used to hold and store liquids, such as beverages, medicines, or cleaning solutions. They come in various materials like glass, plastic, or metal, and are designed to be airtight and leak-proof for optimal preservation and convenience.`,
  },

  {
    id: 46,
    title: "Ice-cream cup",
    category: "Disposable",
    price: 1200,
    img: "./assets/Disposable/6.png",
    desc: `Disposable ice cream cups are convenient, single-use containers designed for serving ice cream, gelato, and other frozen desserts, making them ideal for parties, events, and takeout.`,
  },

  {
    id: 47,
    title: "Bowl",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/2.png",
    desc: `Disposable bowls are versatile, single-use containers ideal for serving snacks, salads, soups, and desserts, making them perfect for parties, picnics, and takeout.`,
  },

  {
    id: 48,
    title: "Tea Cup",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/5.png",
    desc: `Available in various materials, including porcelain, ceramic, and glass, tea cups come in diverse designs and styles, making them perfect for both casual sipping and formal tea settings.`,
  },

  {
    id: 49,
    title: "Baking cake cup",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/7.png",
    desc: `Baking cake cups are convenient, single-use containers designed for baking individual portions of cakes, muffins, or cupcakes, making them perfect for parties, celebrations, or personal treats.`,
  },

  {
    id: 50,
    title: "Box",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/9.png",
    desc: `A box is a versatile container used for storage, organization, or packaging, available in various sizes, shapes, and materials, including cardboard, plastic, and wood.`,
  },

  {
    id: 51,
    title: "Glass",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/14.png",
    desc: `Available in various sizes and designs, water glasses can enhance the aesthetic of a table while providing a practical and refreshing way to stay hydrated throughout the day.`,
  },
  {
    id: 52,
    title: "Box",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/10.png",
    desc: `Boxes can serve multiple purposes, from keeping items tidy at home to providing attractive packaging for gifts and products, making them essential in everyday life and commerce`,
  },

  {
    id: 53,
    title: "Paper plates",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/15.png",
    desc: `Paper plates are disposable, lightweight dining plates made from paper or cardboard, designed for convenience during meals, parties, and outdoor events.`,
  },
  {
    id: 54,
    title: "Box",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/11.png",
    desc: `Boxes can serve multiple purposes, from keeping items tidy at home to providing attractive packaging for gifts and products, making them essential in everyday life and commerce.`,
  },

  {
    id: 55,
    title: "Drink Glass",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/Glass (2).png",
    desc: `Available in various sizes and designs, drink glasses can enhance the aesthetic of a table while providing a practical and refreshing way to stay hydrated throughout the day.`,
  },
  {
    id: 56,
    title: "Dove Soap",
    category: "Cosmetics",
    price: 1700,
    img: "./assets/cosmetics/Cosmetics/8.png",
    desc: `Dove soap offers a variety of gentle cleansing options designed to nourish and care for the skin. The Pink variant features a soothing formula with a light floral scent, ideal for sensitive skin, while the Beauty Cream Bar combines rich moisturizing cream with a classic clean, providing hydration and a soft, smooth feel. `,
  },

  {
    id: 57,
    title: "Baking tray",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/21.png",
    desc: `A baking tray is a flat, rectangular kitchen tool used for baking a variety of foods, including cookies, pastries, and roasted vegetables, and is typically made from metal or silicone.`,
  },
  {
    id: 58,
    title: "Paper box",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/13.png",
    desc: `Paper Boxes can serve multiple purposes, from keeping items tidy at home to providing attractive packaging for gifts and products, making them essential in everyday life and commerce`,
  },
  {
    id: 59,
    title: "Containers",
    category: "Disposable",
    price: 1000,
    img: "./assets/Disposable/20.png",
    desc: `Available in various sizes and designs, these containers provide secure closures to prevent spills and maintain freshness, making them ideal for both hot and cold foods. Their lightweight and recyclable options also contribute to easy cleanup and sustainability, catering to a wide range of food storage needs.`,
  },
];
export default menu;

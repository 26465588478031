import React, { useEffect, useState } from "react";
import Des from "../Components/about/Des";
import Layout from "../Components/Layout";
import "./style.css";
import { Link } from "react-router-dom";


const About = () => {
  const [dots, setDots] = useState([]);
  const [angle, setAngle] = useState(0); // State to manage the angle for rotation

  useEffect(() => {
    // Initialize the dots
    const initialDots = Array.from({ length: 10 }, (_, index) => ({
      radius: Math.random() * 5 + 2, // Random radius for each dot
      color: `hsl(${Math.random() * 360}, 50%, 50%)`, // Random color for each dot
      index: index, // Store the index for position calculation
    }));
    setDots(initialDots);

    // Update the angle for rotation every frame
    const animation = setInterval(() => {
      setAngle(prevAngle => (prevAngle + 1) % 360); // Increment angle
    }, 100); // Adjust the speed of rotation here

    // Clear the interval on unmount
    return () => clearInterval(animation);
  }, []);

  // Calculate the positions of the dots
  const radius = 25; // Radius of the circular path for dots
  const centerX = 50; // Center X position
  const centerY = 50; // Center Y position

  // Image URL (replace with your image path)
  const imageUrl = "./assets/About1.png"; // Update this to your image path
  return (
    <Layout>
      <div className="container-fluid about">
        <div className="row">
          <div className="col-md-7">
            <div className="left-side">
              {/* Content for the left side */}
              <div className="content">
                <h1>About us</h1>
                <hr />
               
                  
                    <div className="card mt-5 px-2" id="card" style={{ width: "25rem" }}>
                      <p id="para" >
                        <img
                          src="./assets/logo1.png" alt="logo"
                          style={{ width: "25px" }}
                        />
                        The Bao Zong Hao Limited website features a diverse
                        range of products, including paper, cosmetics, garments,
                        stationery, and disposables. It serves as a
                        comprehensive platform for showcasing their offerings,
                        catering to both individual and business needs with a
                        focus on quality and variety.
                      </p>
                   
                  
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 ">
            <div className="">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                className="svg-container"
              >
                {/* Render the rotating image at the center */}
                <image
                  href={imageUrl}
                  x={centerX - 15} // Center the image horizontally
                  y={centerY - 15} // Center the image vertically
                  width="30" // Set the width of the image
                  height="30" // Set the height of the image
                />
                {dots.map((dot, index) => {
                  // Calculate the angle for each dot based on its index
                  const dotAngle = (angle + index * 360 / dots.length) % 360;
                  const dotX =
                    centerX + radius * Math.cos(dotAngle * Math.PI / 180);
                  const dotY =
                    centerY + radius * Math.sin(dotAngle * Math.PI / 180);

                  return (
                    <circle
                      key={index}
                      cx={dotX}
                      cy={dotY}
                      r={dot.radius}
                      fill={dot.color}
                    />
                  );
                })}
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* ////welcome div//// */}
      <div className="container welcome my-4 p-3">
        <div className="row">
          <div className="col-md-3 p-4">
            <img
              src="./assets/AboutUs.png" alt="about"
              style={{ height: "130px", width: "140px" }}
            />
          </div>
          <div className="col-md-5  m-auto">
            <h2 style={{ color: "#fff" }}>Everyone Welcome Here</h2>
          </div>
          <div className="col-md-4 m-auto text-center">
            <Link to="/contact">
            <button className="btnn">Contact Us</button></Link>
          </div>
        </div>
      </div>
      {/* ////////////////// */}
      <Des />
    </Layout>
  );
};

export default About;

export const navData = [
  {
    name: "Home",
    path: "/",
    icon: "fa-solid fa-house",
  },
  {
    name: "About",
    path: "/about",
    icon: "fa-solid fa-briefcase",
  },
  {
    name: "Products",
    path: "/product",
    icon: "fa-solid fa-user",
  },
  {
    name: "Career",
    path: "/job",
    icon: "fa-solid fa-user",
  },
  {
    name: "List of Machinery",
    path: "/machinery",
    icon: "fa-solid fa-user",
  },
  {
    name: "Contact us",
    path: "/contact",
    icon: "fa-solid fa-user",
  },
];

export const products = [
  { id: 1, image: "./assets/mix/1.png" },
  { id: 2, image: "./assets/mix/2.png" },
  { id: 3, image: "./assets/mix/3.png" },
  { id: 4, image: "./assets/mix/4.png" },
  { id: 5, image: "./assets/mix/5.png" },
  { id: 6, image: "./assets/mix/6.png" },
  { id: 7, image: "./assets/mix/7.png" },
  { id: 8, image: "./assets/mix/10.png" },
  { id: 9, image: "./assets/mix/11.png" },
  { id: 10, image: "./assets/mix/12.png" },
  { id: 11, image: "./assets/mix/14.png" },
  { id: 12, image: "./assets/mix/15.png" },
  { id: 13, image: "./assets/mix/17.png" },
  { id: 14, image: "./assets/mix/21.png" },
  { id: 15, image: "./assets/mix/22.png" },
  { id: 16, image: "./assets/mix/24.png" },
  { id: 17, image: "./assets/mix/18.png" },
  { id: 18, image: "./assets/mix/19.png" },
  { id: 19, image: "./assets/mix/29.png" },
  { id: 20, image: "./assets/mix/37.png" },
];

export const cos = [
  { id: 1, image: "./assets/cosmetics/main/1.png" },
  { id: 2, image: "./assets/cosmetics/main/2.png" },
  { id: 3, image: "./assets/cosmetics/main/3.png" },
  { id: 4, image: "./assets/cosmetics/main/4.png" },
  { id: 5, image: "./assets/cosmetics/Eye shadow.png" },
  { id: 6, image: "./assets/cosmetics/main/6.png" },
  { id: 7, image: "./assets/cosmetics/main/7.png" },
  { id: 8, image: "./assets/cosmetics/main/8.png" },
  { id: 9, image: "./assets/cosmetics/main/9.png" },
];

export const paper = [
  { id: 1, image: "./assets/Paper/9.png" },
  { id: 2, image: "./assets/Paper/2.png" },
  { id: 3, image: "./assets/Paper/3.png" },
  { id: 4, image: "./assets/Paper/4.png" },
  { id: 5, image: "./assets/Paper/5.png" },
  { id: 6, image: "./assets/Paper/6.png" },
  { id: 7, image: "./assets/Paper/7.png" },
  { id: 8, image: "./assets/Paper/8.png" },
  { id: 9, image: "./assets/Paper/1.png" },
];

export const disposable = [
  { id: 1, image: "./assets/Disposable/main/1.png" },
  { id: 2, image: "./assets/Disposable/main/5.png" },
  { id: 3, image: "./assets/Disposable/main/7.png" },
  { id: 4, image: "./assets/Disposable/main/10.png" },
  { id: 5, image: "./assets/Disposable/main/13.png" },
  { id: 6, image: "./assets/Disposable/main/14.png" },
  { id: 7, image: "./assets/Disposable/main/15.png" },
  { id: 8, image: "./assets/Disposable/main/11.png" },
  { id: 9, image: "./assets/Disposable/main/4.png" },
];

export const machinery = [
  {
    id: 1,
    image: "./assets/Machinery/Roll forming.png",
    title: "Roll Forming",
  },
  {
    id: 2,
    image: "./assets/Machinery/8.png",
    title: "CNC Machinery",
  },
  {
    id: 3,
    image: "./assets/Machinery/CNC Bending Machine.png",
    title: "CNC Bending Machine",
  },
  {
    id: 4,
    image: "./assets/Machinery/CNC Sharing Machine.png",
    title: "CNC Sharing Machine",
  },
  {
    id: 5,
    image: "./assets/Machinery/CNC Spot Welding.png",
    title: "CNC Spot Welding",
  },
  {
    id: 6,
    image: "./assets/Machinery/Pipe Punching.png",
    title: "Pipe Punching",
  },
  {
    id: 7,
    image: "./assets/Machinery/Powder Coating Oven.png",
    title: "Powder Coating Oven",
  },
  {
    id: 8,
    image: "./assets/Machinery/Punching Line.png",
    title: "Punching Line",
  },
  {
    id: 9,
    image: "./assets/Machinery/6.png",
    title: "Machinery",
  },
];
